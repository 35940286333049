export class LoginResponse {
  accessToken: string;
  idToken: string;
  refreshToken: string;
  user: User;

  constructor(opts: {
    accessToken?: string,
    idToken?: string,
    refreshToken?: string,
    user?: User
  } = {}) {
    this.accessToken = opts.accessToken;
    this.idToken = opts.idToken;
    this.refreshToken = opts.refreshToken;
    this.user = opts.user;

  }
}

export class SignupResponse {
  message: string;
  data: LoginResponse;
  result: string;
  constructor(opts: {
    message?: string,
    data?: LoginResponse
  } = {}) {
    this.message = opts.message;
    this.data = opts.data;
  }
}

export class User {
  id: string;
  authType: string;
  authId: string;
  type: string;
  photo: string;
  active: boolean;
  phoneNumberVerfied: boolean;
  phone_number: string;
  emailVerfied: boolean;
  userType: string;
  name: string;
  phone: string;
  email: string;
  password: string;
  provider: string;


  constructor(opts: {
    userType?: string,
    name?: string,
    phone?: string,
    email?: string,
    photo?: string,
    type?: string,
    id?: string,
    phone_number?: string,
    active?: boolean,
    password?: string,
    authType?: string,
    authId?: string,
    phoneNumberVerfied?: boolean,
    emailVerfied?: boolean,
    provider?: string
  } = {}) {
    this.userType = opts.userType;
    this.name = opts.name;
    this.phone = opts.phone;
    this.email = opts.email;
    this.photo = opts.photo;
    this.phone_number = opts.phone_number;
    this.type = opts.type;
    this.password = opts.password;
    this.id = opts.id;
    this.authType = opts.authType;
    this.authId = opts.authId;
    this.active = opts.active;
    this.phoneNumberVerfied = opts.phoneNumberVerfied;
    this.emailVerfied = opts.emailVerfied;
    this.provider = opts.provider;

  }
}
export interface CodeDeliveryDetails {
  AttributeName: string;
  DeliveryMedium: string;
  Destination: string;
}
export class ForgotPasswordResponse {
  codeDeliveryDetails: string;
  constructor(opts: {
    CodeDeliveryDetails?: string,
  } = {}) {
    this.codeDeliveryDetails = opts.CodeDeliveryDetails || '';
  }
}
