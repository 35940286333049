import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    public allowRoutes: string[] = [
        '/sp/my-drivers',
        '/sp/my-vehicles',
        '/sp/wallet',
        '/sp/my-trips',
        '/sp/my-rating'
    ];

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const resp = JSON.parse(localStorage.getItem('user'))
        if (localStorage.getItem('accessToken') && resp.status === 2) {
            return true;
        }
        else if (localStorage.getItem('accessToken') && [1,3].includes(resp.status)) {
            if (this.allowRoutes.includes(state.url)) {
                this.router.navigate(['/sp-profile/profile-docs']);
                return false;
            } else if (['/sp-profile/profile-docs', '/my-profile', '/sp-profile/profile', '/my-profile/change-password'].includes(state.url) && [1,3].includes(resp.status)) {
                return true;
            } else {
                if (['/sp-profile/profile-docs', '/my-profile', '/sp-profile/profile', '/my-profile/change-password'].includes(state.url) && [1,3].includes(resp.status)) {
                    return true;
                }
            }
        }
        else {
            this.router.navigate(['/get-started']);
            return false;
        }
    }
}
