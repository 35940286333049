import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'logibid-error',
  template: "<span block='error' style='color: red;font-size:12px' *ngIf='active'><ng-content></ng-content></span>",
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  @Input() active: boolean;

  constructor() { }

  ngOnInit() { }

}
