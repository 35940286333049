import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './guards/auth_guards';
import {PublicGuard} from './guards/public_guards';
import {NgxPermissionsGuard} from 'ngx-permissions';

const routes: Routes = [
    {
        path: 'get-started',
        loadChildren: 'src/app/home/home.module#HomeModule',
        canActivate: [PublicGuard]
    },
    {
        path: 'login',
        loadChildren: 'src/app/login/login.module#LoginModule',
        canActivate: [PublicGuard]
    },
    {
        path: 'forgot-password',
        loadChildren: 'src/app/forgot-password/forgot-password.module#ForgotPasswordModule',
        canActivate: [PublicGuard],
    },
    {
        path: 'signup',
        loadChildren: 'src/app/signup/signup.module#SignupModule',
        canActivate: [PublicGuard]
    },
    {
        path: 'vehicle-selector',
        canLoad: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: ['SERVICE_PROVIDER'],
                redirectTo: '/sp/my-trips'
            }
        },
        loadChildren: 'src/app/vehicle-selector/vehicle-selector.module#VehicleSelectorModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'location-selector',
        canLoad: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: ['SERVICE_PROVIDER'],
                redirectTo: '/sp/my-trips'
            }
        },
        loadChildren: 'src/app/location-selector/location-selector.module#LocationSelectorModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'payment',
        canLoad: [NgxPermissionsGuard],
        loadChildren: 'src/app/payment/payment.module#PaymentModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'auctions',
        canLoad: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: ['SERVICE_PROVIDER'],
                redirectTo: '/sp/my-trips'
            }
        },
        canActivate: [AuthGuard],
        loadChildren: 'src/app/auctions/auctions.module#AuctionsModule',
    },
    {
        path: 'my-profile',
        canLoad: [NgxPermissionsGuard],
        loadChildren: 'src/app/user-profile/user-profile.module#UserProfileModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'public',
        loadChildren: 'src/app/public-pages/public-pages.module#PublicPagesModule'
    },
    {
        path: 'sp-profile',
        canLoad: [NgxPermissionsGuard],
        loadChildren: 'src/app/sp-profile/sp-profile.module#SpProfileModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'sp-bids',
        canLoad: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: ['SERVICE_PROVIDER', 'DRIVER'],
                redirectTo: '/sp/my-trips'
            }
        },
        loadChildren: 'src/app/sp-bids/sp-bids.module#SpBidsModule',
        canActivate: [AuthGuard]
    }, {
        path: 'sp',
        loadChildren: 'src/app/sp-my/sp-my.module#SpMyModule',
        canActivate: [AuthGuard]
    }, {
        path: '**',
        redirectTo: 'sp-profile/profile'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
