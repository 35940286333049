import {Injectable} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {map, catchError, switchMap, finalize, take, filter} from 'rxjs/operators';
import {Router, ActivatedRoute} from '@angular/router';

import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';
import {ErrorHandlerService} from './error-handler.service';

@Injectable()
export class Backend implements HttpInterceptor {
    queryParams: object = {};
    isRefreshingToken: boolean;
    newReq: any;

    constructor(
        private auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private errorHandler: ErrorHandlerService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.newReq = request;
        const accessToken = localStorage.getItem('accessToken');
        const idToken = localStorage.getItem('idToken');

        if (accessToken) {
            this.newReq = this.newReq.clone({headers: this.newReq.headers.set('Authorization', `JWT ${accessToken}`)});
        }
        if (idToken) {
            this.newReq = this.newReq.clone({headers: this.newReq.headers.set('ID', `${idToken}`)});
        }
        const language = localStorage.getItem('language') || 'en';
        const currencyCode = localStorage.getItem('currencyCode') || 'INR';
        this.newReq = this.newReq.clone({headers: this.newReq.headers.set('Accept-Language', language)});
        this.newReq = this.newReq.clone({headers: this.newReq.headers.set('currencyCode', currencyCode)});
        this.newReq = this.newReq.clone({url: `${environment.apiBaseUrl}${this.newReq.url}`});

        return next.handle(this.newReq).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    event = event.clone({body: event.body.data});
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                data = {
                    reason: error && error.error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };
                this.errorHandler.handleError(error);

                // if (!error.url.includes('refresh')) {
                //     if (error.status === 401) {
                //         this.isRefreshingToken = true;
                //         this.auth.refreshTokenFetch().subscribe((token: any) => {
                //             localStorage.setItem('accessToken', token.accessToken);
                //             this.newReq = this.newReq.clone({headers: this.newReq.headers.set('Authorization', `JWT ${accessToken}`)})
                //             this.isRefreshingToken = false;
                //             return next.handle(this.newReq);
                //
                //         }, () => {
                //             this.isRefreshingToken = false;
                //             this.auth.logout();
                //         });
                //     } else {
                //         // this.errorHandler.handleError(error);
                //     }
                // }
                return throwError(error);
            }));
    }
}

